import { useState } from "react"
import { Box, Text, Textarea } from "@chakra-ui/react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { memo } from "react"

import type { SyntheticEvent } from "react"
import { useCart } from "@app/hooks/useCart"
import { useCartContext } from "@app/providers/cart"

const CartDeliveryInstructions = () => {
  const locales = useLocale(LOCALE_KEYS.CART)
  const { updateCartNote } = useCart()
  const { cart } = useCartContext()
  const [textAreaValue, setTextAreaValue] = useState(cart?.note || "")

  const handleTextAreaChange = (event: SyntheticEvent) => {
    updateCartNote(event?.target?.value || "")
    setTextAreaValue(event?.target?.value)
  }

  return (
    <Box pt={4}>
      {locales?.deliveryInstructions && <Text pb={2.5}>{locales?.deliveryInstructions}</Text>}
      <Textarea w="full" placeholder="Message" maxW="640px" rows={1} value={textAreaValue} onChange={handleTextAreaChange} />
    </Box>
  )
}

const MemoCartDeliveryInstructions = memo(CartDeliveryInstructions)
export { MemoCartDeliveryInstructions as CartDeliveryInstructions }
