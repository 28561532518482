import { graphql, PageProps } from "gatsby"
import Page from "@components/Cart/Cart"

export type Props = PageProps<GatsbyTypes.PageCartQuery, GatsbyTypes.PageCartQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageCart {
    page: sanityPageCart {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      button: _rawButton(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
