import { Box, Text, SkeletonText, Skeleton, AspectRatio } from "@chakra-ui/react"
import { CustomImage } from "@components/Image"
import { BrandedProductTitle } from "@components/BrandedProductTitle"
import { memo } from "react"

const FreeSamplesImage = ({ item }: any) => {
  const shopifyImg = item?.images?.[0]?.originalSrc
  const sanityImg = item?.image

  if (!shopifyImg && !sanityImg) {
    return (
      <AspectRatio width="full" ratio={1}>
        <Skeleton />
      </AspectRatio>
    )
  }

  return (
    <Box position="relative" width="full">
      <CustomImage aspectRatio="square" src={shopifyImg || sanityImg} alt={item?.title} width="full" />
    </Box>
  )
}

const FreeSamplesTitle = ({ item }: any) => {
  if (!item?.title) {
    return <SkeletonText noOfLines={2} mt={[0, 2.5, 2.5]} />
  }

  return (
    <Text noOfLines={2} mt={[0, 2.5, 2.5]} fontSize="sm">
      <BrandedProductTitle item={item} />
    </Text>
  )
}

const MemoFreeSamplesTitle = memo(FreeSamplesTitle)
const MemoFreeSamplesImage = memo(FreeSamplesImage)
export { MemoFreeSamplesTitle as FreeSamplesTitle, MemoFreeSamplesImage as FreeSamplesImage }
