import { memo, useState, useEffect } from "react"
import { Box, Spinner } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useCustomerContext } from "@app/providers/customer"
import { useCustomerOrders } from "@app/hooks/useCustomer"
import { FeaturedProductsWrapper } from "@components/Sections/FeaturedProducts/FeaturedProductsWrapper"
import { useShopify } from "@app/hooks/useShopify"

import type { Location } from "@root/types/global"

type CartForgetSomethingProps = {
  location: Location
  cartForgetSomething: GatsbyTypes.StaticCartQuery["cartForgetSomething"]
}

const forgetSomethingStyle = `
  .featProductsContainer {
    padding-left: 0;
    padding-right: 0;

    .wishlist-ui {
      display: none;
    }

    .productStars {
      margin-top: 8px !important;
    }

    .swiper-slide {
      width: 310px !important;

      @media (min-width: 520px) {
        width: 380px !important;
      }

      @media (min-width: 900px) {
        max-width: 170px !important;
      }
    }
  }
`

// TODO: test this
const CartForgetSomething: React.FC<CartForgetSomethingProps> = ({ location, cartForgetSomething }) => {
  const { getProducts, getCollection } = useShopify()
  const [forgetProducts, setForgetProducts] = useState([])
  const defaultLimit = 8
  const { orders, loading } = useCustomerOrders(defaultLimit)
  const orderProductHandles: string[] = []
  const { customer } = useCustomerContext()

  orders?.forEach(order => {
    order?.lineItems?.forEach(lineItem => {
      if (
        lineItem?.merchandise?.product &&
        lineItem?.merchandise?.availableForSale &&
        lineItem?.merchandise?.product?.handle !== "free-sample"
      ) {
        orderProductHandles.push(lineItem?.merchandise?.product?.handle)
      }
    })
  })

  const previousOrdersProductHandles = orderProductHandles.slice(0, cartForgetSomething?.productCount || defaultLimit)

  useEffect(
    () => {
      const fetchProducts = async handles => {
        const previousOrdersProducts = await getProducts({
          firstVariants: 1,
          firstImages: 1,
          handles: handles,
        })
        setForgetProducts(previousOrdersProducts.filter(product => product?.availableForSale))
      }

      const fetchCollection = async handle => {
        const collection = await getCollection({
          firstVariants: 1,
          firstProducts: 8,
          firstImages: 1,
          handle: handle,
          firstMetafields: 3,
        })
        setForgetProducts(collection?.products)
      }

      if (!customer || !previousOrdersProductHandles.length) {
        // handle non logged-in customers or customers with no order history, fetch based off Sanity setting
        if (cartForgetSomething?.sourceType === "manual") {
          const handles = cartForgetSomething?.products?.map(product => product?.shopify?.handle)
          fetchProducts(handles).catch(err => console.error("Failed to fetch Forget Something products (from Sanity)", err))
        } else {
          fetchCollection(cartForgetSomething?.collection?.shopify?.handle).catch(err =>
            console.error("Failed to fetch Forget Something collection", err)
          )
        }
      } else {
        // fetch based off previous order history
        fetchProducts(previousOrdersProductHandles).catch(err =>
          console.error("Failed to fetch Forget Something products (from order history)", err)
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer, previousOrdersProductHandles.length]
  )

  if (loading) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  return forgetProducts.length ? (
    <Box
      pb={[0, 0, 4]}
      borderTop={["none", "none", "2px"]}
      pt={[0, 0, 4]}
      position="relative"
      overflow="hidden"
      bgColor={cartForgetSomething?.bgColor || "brand.offwhite"}
    >
      <Box css={forgetSomethingStyle} pb="3" borderBottom={["1px", 0]}>
        <FeaturedProductsWrapper
          isMinimal
          location={location}
          title={cartForgetSomething?.title || "Forget Something..."}
          products={forgetProducts}
          bgColor={cartForgetSomething?.bgColor || "brand.offwhite"}
          wishListButtonProps={{
            showOutline: false,
          }}
        />
      </Box>
    </Box>
  ) : null
}

const MemoCartForgetSomething = memo(CartForgetSomething)
export { MemoCartForgetSomething as CartForgetSomething }
