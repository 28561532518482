import { memo } from "react"
import { Button, HStack } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"

type ProductWriteReviewProps = {
  product: any
  fontSize?: string | Array<string>
  marginBottom?: number | Array<number>
}

const ProductWriteReview: React.FC<ProductWriteReviewProps> = ({ product, fontSize = ["sm", "sm", "md"], marginBottom = 0 }) => {
  return (
    <HStack spacing={4} align="stretch">
      <CustomLink
        to={`/review/write/${product?.handle}`}
        styles={{
          w: "full",
        }}
      >
        <Button variant="solid" w="full" p="unset" maxW="full" mb={marginBottom} fontSize={fontSize} minW="unset">
          Write a Review
        </Button>
      </CustomLink>
    </HStack>
  )
}

const MemoProductWriteReview = memo(ProductWriteReview)
export { MemoProductWriteReview as ProductWriteReview }
