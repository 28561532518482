import { memo, useMemo, useState } from "react"
import { Box } from "@chakra-ui/react"
import { useCore } from "@app/hooks/useCore"
import { useOutOfStock } from "@app/hooks/useOutOfStock"
import ProductCardDesktop from "./ProductCardDesktop"
import ProductCardMobile from "./ProductCardMobile"
import { useSubscriptionContext } from "@app/providers/subscription"
import { useSubscriptions } from "@app/hooks/useSubscriptions"
import type { Location } from "@root/types/global"
import type { Product, Collection } from "shopify-storefront-api-typings"
import type { WishListButtonProps } from "@components/WishList/WishListButton"

type ProductCardProps = {
  item: Product | GatsbyTypes.SanityProduct
  collection?: Collection | GatsbyTypes.SanityCollection | undefined
  itemRef?: any
  handleTrackingClick?: () => void
  location?: Location
  maxW?: string
  isPlp?: boolean
  isReview?: boolean
  bgColor?: any
  wishListButtonProps?: WishListButtonProps
  maxWidthMobile?: string
  maxWidthDesktop?: string
}

type purchaseRecurrence = "monthly" | "one-time"

const getSelectedVariant = ({ item, selectedVariantIdx }: { item: any; selectedVariantIdx: number }) => {
  const isSanityProduct = !!item?.shopify
  const isShopifyProduct = !isSanityProduct

  if (isSanityProduct && typeof item?.shopify?.raw === "string") {
    return JSON.parse(item?.shopify?.raw)?.variants?.[0]
  }

  if (isShopifyProduct) {
    const moreThanOneVariant = item?.variants?.length > 1

    if (moreThanOneVariant) {
      return item?.variants?.[selectedVariantIdx]
    } else {
      return item?.variants?.[0]
    }
  }
}

const ProductCard: React.FC<ProductCardProps> = ({
  item,
  itemRef,
  handleTrackingClick,
  location,
  maxW = "full",
  isPlp = false,
  isReview = false,
  bgColor = "brand.offWhite",
  wishListButtonProps,
  collection = null,
  maxWidthDesktop,
  maxWidthMobile,
}) => {
  const [selectedVariantIdx, setSelectedVariantIdx] = useState(0)
  const [purchaseRecurrence, setPurchaseRecurrence] = useState<purchaseRecurrence>("monthly")

  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { checkIfSubscriptionProduct } = useSubscriptionContext()
  const { subscriptionDinkus } = useSubscriptions()

  const selectedVariant = useMemo(() => getSelectedVariant({ item, selectedVariantIdx }), [item, selectedVariantIdx])

  const { checkIfOutOfStock } = useOutOfStock()
  const isOutOfStock = checkIfOutOfStock({ variant: selectedVariant, product: item })

  const productId = useMemo(() => decodeShopifyId(item?.id, "Product"), [item?.id, decodeShopifyId])
  const { isSubscriptionProduct } = checkIfSubscriptionProduct({ productId })
  const subscriptionType = (isSubscriptionProduct as any)?.subscription_defaults?.storefront_purchase_options || null
  const isSubscriptionAndOneTimeProduct = subscriptionType === "subscription_and_onetime"
  const isSubscriptionCheckout = isSubscriptionAndOneTimeProduct
    ? purchaseRecurrence === "monthly"
      ? true
      : false
    : isSubscriptionProduct
    ? true
    : false
  const subscriptionDiscountPercentage = Number((isSubscriptionProduct as any)?.discount_amount) || undefined
  const subscriptionDinkusMessage = subscriptionDinkus?.dinkusMessage?.replace(`{{X%}}`, `${subscriptionDiscountPercentage}%`)

  return (
    <>
      <Box
        maxW={maxW}
        ref={itemRef}
        h="full"
        w="full"
        display={["none", "none", "flex"]}
        justifyContent="center"
        flexDir="column"
        textAlign="left"
        bg={["brand.offWhite", bgColor || "brand.offWhite", "inherit"]}
        borderRadius={["base", "base", "none"]}
      >
        <ProductCardDesktop
          item={item}
          handleTrackingClick={handleTrackingClick}
          selectedVariant={selectedVariant}
          location={location}
          isPlp={isPlp}
          isReview={isReview}
          setSelectedVariantIdx={setSelectedVariantIdx}
          selectedVariantIdx={selectedVariantIdx}
          isOutOfStock={isOutOfStock}
          wishListButtonProps={wishListButtonProps}
          collection={collection}
          productId={productId}
          maxWidthDesktop={maxWidthDesktop}
          subscriptionData={{
            isSubscriptionProduct,
            isSubscriptionCheckout,
            isSubscriptionAndOneTimeProduct,
            subscriptionDiscountPercentage,
            subscriptionDinkusMessage,
            purchaseRecurrence,
            setPurchaseRecurrence,
          }}
        />
      </Box>
      <Box
        maxW={maxW}
        ref={itemRef}
        h="full"
        w="full"
        justifyContent="center"
        flexDir="column"
        textAlign="left"
        bg={[bgColor || "brand.offWhite", bgColor || "brand.offWhite", "inherit"]}
        borderRadius={["base", "base", "none"]}
        display={["flex", "flex", "none"]}
      >
        <ProductCardMobile
          item={item}
          handleTrackingClick={handleTrackingClick}
          selectedVariant={selectedVariant}
          location={location}
          isPlp={isPlp}
          isReview={isReview}
          setSelectedVariantIdx={setSelectedVariantIdx}
          selectedVariantIdx={selectedVariantIdx}
          isOutOfStock={isOutOfStock}
          wishListButtonProps={wishListButtonProps}
          collection={collection}
          productId={productId}
          maxWidthMobile={maxWidthMobile}
          disableLazyLoad={true}
          subscriptionData={{
            isSubscriptionProduct,
            isSubscriptionCheckout,
            isSubscriptionAndOneTimeProduct,
            subscriptionDiscountPercentage,
            subscriptionDinkusMessage,
            purchaseRecurrence,
            setPurchaseRecurrence,
          }}
        />
      </Box>
    </>
  )
}

const MemoProductCard = memo(ProductCard)
export { MemoProductCard as ProductCard }
