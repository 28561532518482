import { useEffect, useCallback } from "react"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useInView } from "react-intersection-observer"

type useSectionReturn = {
  innerRef: any
  inView: boolean
  handleTrackingClick: () => void
}

type useSectionProps = {
  analytics?: GatsbyTypes.SanityAnalytics
}

const useSection = ({ analytics }: useSectionProps): useSectionReturn => {
  const { trackPromoImpression, trackPromoClick } = useAnalytics()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView && analytics) trackPromoImpression(analytics)
  }, [inView, analytics, trackPromoImpression])

  const handleTrackingClick = useCallback(() => {
    if (analytics) trackPromoClick(analytics)
  }, [analytics, trackPromoClick])

  return {
    innerRef: ref,
    inView,
    handleTrackingClick,
  }
}

export { useSection }
