import { memo } from "react"
import { useGifts } from "@app/hooks/useGifts"

import { css } from "@emotion/react"
import { Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react"
import { FreeSamplesItem } from "./FreeSamplesItem"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import { CarouselOverflowWrapper, extraOverflowParentProps } from "@app/components/CarouselOverflowWrapper"

import type { Product } from "shopify-storefront-api-typings"

type FreeSamplesProps = {
  key: string
  sample: Product
  message: string
}

const freeSamplesStyle = css`
  @media (max-width: 600px) {
    .swiper {
      .swiper-slide {
        flex: 0 0 calc(50% - 6px);
      }
    }
  }
`

const FreeSamples: React.FC = () => {
  const { samples, cartTitle } = useGifts()

  return samples.length ? (
    <Box css={freeSamplesStyle} mb={[0]} mt={[12, 6, 6]} borderTop={["2px"]} {...extraOverflowParentProps}>
      <Accordion defaultIndex={[0]} allowToggle mt={0}>
        <AccordionItem borderTop="unset" borderBottom="unset" mb={3}>
          {cartTitle && (
            <AccordionButton px={0} fontSize="xl" _hover={{ bgColor: "unset" }}>
              <Box fontWeight={700} fontSize="16px" py={5}>
                {cartTitle}
              </Box>
              <AccordionIcon marginLeft="auto" />
            </AccordionButton>
          )}
          <AccordionPanel px={0} border="none">
            <CarouselOverflowWrapper type="samples">
              <Swiper modules={[Navigation]} navigation pagination={{ clickable: true }} slidesPerView="auto" spaceBetween={12}>
                {samples?.map((sample: FreeSamplesProps) => (
                  <SwiperSlide key={sample?.key}>
                    <FreeSamplesItem key={sample?.key} item={sample} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </CarouselOverflowWrapper>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  ) : null
}

const MemoFreeSamples = memo(FreeSamples)
export { MemoFreeSamples as FreeSamples }
