import { memo } from "react"
import { Box, Text, useCheckbox, UseCheckboxProps, Flex } from "@chakra-ui/react"
import { CustomIcon } from "@components/Icon"

interface CustomCheckboxProps extends UseCheckboxProps {
  children: string | React.ReactNode
  name: string
  handleChange: (arg1: any) => void
  checked?: boolean
  fontSize?: string
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ handleChange, children, checked, name, fontSize = "xs" }) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox({
    value: name,
    isChecked: checked,
    onChange: handleChange,
  })

  return (
    <Flex as="label" {...htmlProps} cursor="pointer" alignItems="center">
      <input {...getInputProps()} hidden />
      <Box {...getCheckboxProps()} m={0}>
        {state.isChecked ? (
          <CustomIcon name="radio-checked" width={17} height={17} />
        ) : (
          <CustomIcon name="radio-unchecked" width={17} height={17} />
        )}
      </Box>
      <Text mr={1} ml={2.5} {...getLabelProps()} fontSize={fontSize}>
        {children}
      </Text>
    </Flex>
  )
}

const MemoCustomCheckbox = memo(CustomCheckbox)
export { MemoCustomCheckbox as CustomCheckbox }
