import { useState } from "react"
import { Box } from "@chakra-ui/react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { CustomCheckbox } from "@components/Checkbox"
import { useConfigContext } from "@app/providers/config"
import { memo } from "react"
import { useCart } from "@app/hooks/useCart"
import { useCartContext } from "@app/providers/cart"

const CartAuthorityToLeave = () => {
  const locales = useLocale(LOCALE_KEYS.CART)
  const { cart } = useCartContext()
  const { updateAttributes } = useCart()
  const {
    settings: {
      attributes: { authorityToLeave },
    },
  } = useConfigContext()
  const [atlEnabled, setAtlEnabled] = useState<boolean>(
    !!cart?.attributes?.find(attr => attr?.key === authorityToLeave && attr.value === "true")
  )

  const handleAtlChange = () => {
    setAtlEnabled(!atlEnabled)
    updateAttributes([
      { key: authorityToLeave, value: (!atlEnabled)?.toString() },
      { key: "Authority To Leave", value: atlEnabled ? "No" : "Yes" },
    ])
  }

  return (
    <Box pt={4} pb={4}>
      <CustomCheckbox name="atl" handleChange={handleAtlChange} checked={atlEnabled} fontSize="sm">
        {locales?.authorityToLeave}
      </CustomCheckbox>
    </Box>
  )
}

const MemoCartAuthorityToLeave = memo(CartAuthorityToLeave)
export { MemoCartAuthorityToLeave as CartAuthorityToLeave }
