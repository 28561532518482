import { memo } from "react"
import { Button } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { useRoutes } from "@app/hooks/useRoutes"

type CustomButtonProps = {
  button?: GatsbyTypes.SanityButton
  url?: string
  width?: any
  size?: any
  center?: boolean
  fontSize?: string
  linkStyles?: any
  buttonStyles?: any
  onClick?: () => void
}

// Usage: pass the button returned from Sanity's objectButton, and a resolvedUrl
// Returns a Button wrapped in a link, with the appropriate variant set

const CustomButton: React.FC<CustomButtonProps> = ({
  button,
  url,
  width,
  size,
  center,
  fontSize,
  buttonStyles,
  linkStyles,
  linkScrollsToTop = false,
}) => {
  const { urlResolver } = useRoutes()

  if (!button) return null

  // TODO: fix the button linking if no button link is selected in sanity

  const hideButton = !button || !button?.text || button?.type === "hidden"
  if (hideButton) return null

  const resolvedButtonLink = button?.link || button?.external ? urlResolver(button?.link || button?.external) : ""

  return (
    <CustomLink
      to={linkScrollsToTop ? "#gatsby-focus-wrapper" : resolvedButtonLink?.url || url}
      title={button?.link?.title || button?.text}
      styles={{
        w: width,
        display: center ? "block" : "inline-block",
        textAlign: center ? "center" : "auto",
        ...linkStyles,
      }}
    >
      <Button variant={button?.type} width={width} size={size} fontSize={fontSize} {...buttonStyles}>
        {button?.text}
      </Button>
    </CustomLink>
  )
}

const MemoCustomButton = memo(CustomButton)
export { MemoCustomButton as CustomButton }
