import { useState, memo, useCallback } from "react"
import { Box, Button, Text } from "@chakra-ui/react"
import { CustomIcon } from "@components/Icon"
import { useLocale, LOCALE_KEYS } from "@app/hooks/useLocale"
import { useWishListContext } from "@app/providers/wishlist"
import { useCore } from "@app/hooks/useCore"
import { useCustomerContext } from "@app/providers/customer"
import { navigate } from "gatsby"
import { useConfigContext } from "@app/providers/config"

import type { ProductVariant, Product } from "shopify-storefront-api-typings"
import type { Location } from "@root/types/global"

type WishListButtonProps = {
  location?: Location
  product?: Product
  variant?: ProductVariant
  fontSize?: string | Array<string>
  height?: number | Array<number>
  iconWidth?: number
  iconTextGap?: any
  showUnderline?: boolean
  showOutline?: boolean
  showBgColor?: boolean
}

type WishListDropdownOptionsProps = {
  wishLists?: Array<any>
  handleClick: (list: any) => void
  borderColor?: string
}

const expandedButtonStyles = {
  borderBottomColor: "brand.offWhite",
  borderBottomLeftRadius: "none",
  borderBottomRightRadius: "none",
  color: "brand.avocado",
  _hover: {
    bgColor: "brand.offWhite",
  },
}

// const expandedButtonTextStyles = {
//   color: "brand.avocado",
//   _hover: {
//     bgColor: "brand.offWhite",
//     color: "brand.avocado",
//   },
// }

// Component usage:
// 1. Wrap it in a <Box> with an explicit with to set the width of the button, otherwise
// it expands to 100%

// Generated with https://codepen.io/sosuke/pen/Pjoqqp from brand.offWhite
const WHITE_ICON_FILTER = "invert(100%) sepia(73%) saturate(383%) hue-rotate(296deg) brightness(104%) contrast(101%)"

const WishListDropdownOptions: React.FC<WishListDropdownOptionsProps> = ({ wishLists, handleClick }) => {
  return (
    <Box
      w="full"
      p={2}
      // borderX="1px"
      // borderBottom="1px"
      borderBottomRadius="base"
      position="absolute"
      bg="brand.offWhite"
      zIndex={3}
      border="1px"
      borderColor="brand.avocado"
    >
      {wishLists?.map((list: SwymList) => (
        <Text key={list?.lid} fontSize="md" p={1} cursor="pointer" _hover={{ bgColor: "gray.100" }} onClick={() => handleClick(list)}>
          {list?.lname}
        </Text>
      ))}
    </Box>
  )
}

const WishListButton: React.FC<WishListButtonProps> = ({
  product,
  fontSize = ["md", "xl"],
  iconWidth = 19,
  variant,
  iconTextGap = [1, 1, 3],
  height,
  showUnderline = false,
  showBgColor = true,
  showOutline = true,
}) => {
  // State setters
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)

  // External hook calls
  const { fetched, checkIfInWishList, addToWishList, removeFromWishList, wishLists } = useWishListContext()
  const { customer } = useCustomerContext()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const {
    settings: { routes },
  } = useConfigContext()

  // Helper variables
  const buttonLoading = loading || !fetched
  const locales = useLocale(LOCALE_KEYS.SHOPPING_LIST)
  // const isUnstyled = variant === "unstyled"
  // activeVariant for pdp, selectedVariant for featured products section
  const { isInWishList } = checkIfInWishList(variant)
  const buttonTitle = isInWishList ? locales?.removeFromShoppingListButton : locales?.addtoShoppingListButton
  const buttonBorderColor = showOutline ? "brand.avocado" : "transparent"
  const buttonBgColor = showBgColor || expanded ? "brand.offWhite" : "transparent"
  const textUnderline = showUnderline && !expanded ? "underline" : "none"
  // const boxHoverStyles = expanded ? {} : isUnstyled ? {} : { svg: { filter: ["unset", "unset", WHITE_ICON_FILTER] } }
  // const groupHoverStyles = expanded ? expandedButtonTextStyles : isUnstyled ? {} : { color:  }
  const hideDropdown = wishLists?.length <= 1

  const groupHoverStyles = hideDropdown
    ? {
        color: ["inherit", "inherit", "brand.offWhite"],
        svg: { filter: ["unset", "unset", WHITE_ICON_FILTER] },
      }
    : {
        color: ["inherit", "inherit", "brand.avocado"],
      }

  // Function defs
  const handleButtonClick = useCallback(async () => {
    if (!customer) navigate(routes.LOGIN_REGISTER)

    // If the user clicks on the wishlist button again after expanded
    if (expanded) {
      setExpanded(false)
      return
    }

    // If the product is in the wishlist already, remove it
    if (isInWishList) {
      setLoading(true)
      const decodedProductId = decodeShopifyId(product?.id, "Product")
      const wishListToRemoveFrom = wishLists?.find((list: SwymList) =>
        list?.listcontents?.find((product: SwymProduct) => product?.empi === Number(decodedProductId))
      )
      await removeFromWishList({ productVariant: variant, wishListId: wishListToRemoveFrom?.lid, product })
      setLoading(false)
      return
    }

    // If there's only 1 wishlist, add the product to the default list
    const shouldAddToDefaultList = wishLists?.length <= 1
    if (shouldAddToDefaultList) {
      setLoading(true)
      await addToWishList({ productVariant: variant, product })
      setLoading(false)
      return
    }

    if (wishLists?.length === 0) {
      return false
    }

    // If there's more than 1 wishlist, expand the wishlist
    setExpanded(true)
  }, [
    addToWishList,
    customer,
    decodeShopifyId,
    expanded,
    isInWishList,
    product,
    removeFromWishList,
    routes.LOGIN_REGISTER,
    wishLists,
    variant,
  ])

  const handleOptionClick = useCallback(
    async (list: SwymList) => {
      // Since we already know the item isn't in the wishlist (since the options are expanded),
      // simply add the product to the desired list
      setLoading(true)
      await addToWishList({ productVariant: variant, wishListId: list?.lid, product })
      setLoading(false)
      setExpanded(false)
    },
    [addToWishList, product, variant]
  )

  return (
    <Box w="full" position="relative" role="group" className="wishlist-ui">
      <Button
        w="full"
        minW="unset"
        variant={hideDropdown ? "outline" : "outine-no-hover"}
        bgColor={buttonBgColor}
        display="inline-flex"
        title={buttonTitle}
        onClick={handleButtonClick}
        isDisabled={buttonLoading}
        h={height}
        fontSize={fontSize}
        borderColor={buttonBorderColor}
        {...(expanded && expandedButtonStyles)}
        _groupHover={groupHoverStyles}
      >
        {!isInWishList && <CustomIcon name="shopping-list" width={iconWidth} height={iconWidth} />}
        <Text ml={iconTextGap} fontSize="inherit" textDecoration={textUnderline} color="inherit">
          {buttonTitle}
        </Text>
      </Button>
      {expanded && !hideDropdown && (
        <WishListDropdownOptions handleClick={handleOptionClick} wishLists={wishLists} borderColor={buttonBorderColor} />
      )}
    </Box>
  )
}

const MemoWishListButton = memo(WishListButton)
export { MemoWishListButton as WishListButton }
export type { WishListButtonProps }
