import { useEffect, useMemo, memo } from "react"
import { useLazyQuery } from "@apollo/client"
import { Box } from "@chakra-ui/react"
import { CustomButton } from "@components/Button"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { FeaturedProductsWrapper } from "@components/Sections/FeaturedProducts/FeaturedProductsWrapper"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"

import type { Location } from "@root/types/global"
import { useCartContext } from "@app/providers/cart"

type CartRecommendationProps = {
  location: Location
  button: any
}

const CartRecommendations: React.FC<CartRecommendationProps> = ({ location, button }) => {
  const {
    graphql: {
      queries: { GET_CART_RECOMMENDATIONS },
    },
  } = useCore()
  const { cart, countryCode } = useCartContext()
  const { productNormaliser } = useShopify()

  const locales = useLocale(LOCALE_KEYS.CART)
  const productRecommendationsTitle = locales?.productRecommendationsTitle || ""

  const ids = useMemo(() => cart?.lines?.map(item => item?.merchandise?.product.id), [cart?.lines])

  const [getRecommendations, { data: items, called, error }] = useLazyQuery(GET_CART_RECOMMENDATIONS(ids), {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      countryCode,
      firstImages: 1,
      firstVariants: 1,
    },
  })

  if (error) console.error(error)

  // flatten product objects, remove duplicates, randomise and limit to 9 products
  const recommendations = useMemo(
    () =>
      items
        ? []
            .concat(...Object.values(items).map((products: any) => products?.map(productNormaliser)))
            .filter(item => !item?.tags?.includes("#hidden"))
            .filter((v: any, i: number, a: any) => a.findIndex((t: any) => t.id === v.id) === i)
            // .sort(() => Math.random() - 0.5)
            .slice(0, 8)
        : [],
    [items, productNormaliser]
  )

  useEffect(() => {
    if (!ids || called) return
    getRecommendations()
  }, [ids, called, getRecommendations])

  const Button = useMemo(
    () => <CustomButton button={button} url={button.external.link} linkStyles={{ display: ["none", "none", "block"] }} />,
    [button]
  )

  return (
    <Box overflow="hidden">
      <FeaturedProductsWrapper
        location={location}
        title={productRecommendationsTitle}
        isRecommendations
        products={recommendations}
        button={Button}
        bgColor="brand.offwhite"
        wishListButtonProps={{
          showOutline: false,
        }}
      />
    </Box>
  )
}

const MemoCartRecommendations = memo(CartRecommendations)
export { MemoCartRecommendations as CartRecommendations }
