import { memo } from "react"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useRoutes } from "@app/hooks/useRoutes"
import { useGifts } from "@app/hooks/useGifts"
import { FreeSamplesImage, FreeSamplesTitle } from "./FreeSamplesStyles"
import { Box, Button, Text } from "@chakra-ui/react"
import { CustomLink } from "@components/Link"
import { CustomIcon } from "@components/Icon"

import type { Product } from "shopify-storefront-api-typings"
import { useCartContext } from "@app/providers/cart"

type SampleProps = {
  key: string
  image: object
  sample: Product
  message: string
}

type FreeSamplesItemProps = {
  item: SampleProps
}

const FreeSamplesItem: React.FC<FreeSamplesItemProps> = ({ item }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { cart } = useCartContext()
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { urlResolver } = useRoutes()
  const { adding, handleAddSampleToCart, handleRemoveFromCart, sampleInCart } = useGifts()
  const locales = useLocale(LOCALE_KEYS.CART)

  const decodedVariantId = decodeBase64(item?.sample?.variants?.[0]?.id)

  const currSampleInCart = cart?.lines?.find(
    lineItem =>
      lineItem?.merchandise?.id === decodedVariantId &&
      lineItem?.attributes?.find(field => field.key === "_isSample" && field.value === "true")
  )

  const getButtonText = () => {
    const addToCartTxt = locales?.addToCart || "Add to Cart"
    const addingToCartTxt = locales?.addingToCart || "Adding to Cart"
    const addedToCartTxt = locales?.addedToCart || "Added!"

    if (currSampleInCart) return addedToCartTxt
    if (adding) return addingToCartTxt

    return addToCartTxt
  }

  const buttonText = getButtonText()

  const resolvedProductUrl = item?.sample?.link?.url || urlResolver(item?.sample, routes.PRODUCT)?.url

  return (
    <Box
      h="full"
      w="full"
      display="flex"
      justifyContent="center"
      flexDir="column"
      textAlign="left"
      bg={["brand.offWhite", "brand.offWhite", "inherit"]}
      borderRadius={["base", "base", "none"]}
    >
      <FreeSamplesImage item={item?.sample} />
      <CustomLink to={resolvedProductUrl} title={item?.sample?.title} styles={{ mb: 3 }}>
        <FreeSamplesTitle item={item?.sample} />
      </CustomLink>

      <Box mt="auto">
        <Button
          variant="outlineDisabled"
          w="full"
          p="unset"
          maxW="full"
          mb={[0, 2, 2]}
          onClick={() => handleAddSampleToCart(item?.key)}
          fontSize={["sm", "sm", "md"]}
          isDisabled={adding === item?.key || currSampleInCart || sampleInCart.length}
          minW="unset"
        >
          {buttonText}
        </Button>
        {currSampleInCart ? (
          <Button
            variant="outlineDisabled"
            w="full"
            p="unset"
            maxW="full"
            minW="unset"
            onClick={() => handleRemoveFromCart(currSampleInCart)}
            fontSize={["sm", "sm", "md"]}
            display="inline-flex"
            bg="brand.offWhite"
            borderColor="brand.offWhite"
            title={locales?.cartRemoveProductLabel}
            transition="all 200ms ease-in-out"
            _hover={{
              borderColor: "brand.avocado",
              backgroundColor: "brand.offWhite",
            }}
          >
            <CustomIcon name="trash" width={12} cursor="pointer" title="Remove" />
            <Text ml={[1, 1, 3]}>{locales?.cartRemoveProductLabel}</Text>
          </Button>
        ) : (
          <Box w="full" p="unset" maxW="full" minW="unset" minH="40px" />
        )}
      </Box>
    </Box>
  )
}

const MemoFreeSamplesItem = memo(FreeSamplesItem)
export { MemoFreeSamplesItem as FreeSamplesItem }
